import { React, useState } from 'react';
import { ReactTyped } from 'react-typed';
import { Route, Routes, useLocation } from 'react-router-dom';

import './App.css';

import Header from './Components/Header';
import Footer from './Components/Footer';

import LandingText from './Pages/LandingText';

import About from './Pages/About';
import Portfolio from './Pages/Portfolio';
import Games from './Pages/Games';
import Contact from './Pages/Contact';




import BASE_RATE from './constants';


function App() {

  const currentPage = useLocation().pathname;


  return (
      <div className="App">

        {/* header */}
        <Header currentPage={currentPage} />

        {/* body */}
          <Routes>
            <Route path='/' element={<LandingText />} />
            <Route path="/about" element={<About />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/games" element={<Games />} />
            <Route path="/contact" element={<Contact />} />

          </Routes>

        {/* footer */} 
        <Footer currentPage={currentPage}/>


          

      </div>
  );
}

export default App;
