import { render } from '@testing-library/react';
import { React } from 'react';
import { ReactTyped } from 'react-typed';

const TYPING_RATE = 1;


const paragraphs = [
    'I studied Film and Math at NYU.',

    `While there, thinking I wanted to work in the documentary industry, 
    I had internships on video teams at CNN and The New York Times.`,

    'After graduating in 2020, I worked as a Calculus tutor and freelance writer.',

    `In 2021, after becoming interested in AI, and specifically AI safety, 
    I started teaching myself software engineering and Machine Learning.`,

    `A year later, I got a job at a small healthtech startup.
    For a year and a half, I managed every aspect of their tech stack - I trained ML models and conducted data analysis work,
    I designed custom ML software, and I built their entire web system, handling frontend, backend, and deployment.`,
]

const skills = [
    ['Languages:', 'Python, Javascript, HTML, CSS, C'],
    ['Machine Learning:', 'scikit-learn, PyTorch, H2O.ai, Binary Classification, Regression'],
    ['ML Model Types:', 'Neural Networks, Random Forests, GBMs, Logistic Regression, SVMs '],
    ['Generative AI:', 'transformers, Anthropic API'],
    ['Data Science:', 'numpy, scipy, pandas, matplotlib'],
    ['Web Development:', 'React, Django, SQL, PostgreSQL, AWS, celery, gunicorn, nginx'],
]


const About = () => {


    const renderSkills = () => {

        return (
            <div>
                {skills.map((skill, index) => {
                    return (
                        <div className='container'>
                            <div classname='container' 
                            style={{
                                display: 'flex', flexDirection: 'row',
                                justifyContent: 'flex-start',
                                
                                //border: '1px solid black'
                                }}>

                                <div className='container paragraph-text'
                                style={{
                                    width: '20%',
                                    textAlign: 'right',  
                                    fontStyle: 'italic',
                                }}>
                                    {skill[0]}
                                </div>

                                <div className='container paragraph-text'
                                style={{
                                    width: '80%',
                                    paddingLeft: '20px',
                                }}>
                                    {skill[1]}
                                </div>
                            </div>
                            <div style={{height: '20px'}}/>
                        </div>
                    )
                })}
            </div>
        )

    };
        



    return (
        <div className='container about-container' 
        style={{ 
        textAlign: 'left', lineHeight: '1.5' }}>


            {paragraphs.map((paragraph) => {

                return (
                    <div className='paragraph-text'>
                        {paragraph}
                        <div style={{height: '35px'}}/>
                    </div>
                )
            })}

            <div className='paragraph-text'>
            Over the past two years, I've participated in The Center for ML Safety's&nbsp;

            <a 
            className='link-text'
            style={{fontStyle: 'italic'}}
            href='https://course.mlsafety.org/about' target='_blank' rel='noopener noreferrer'>
            Introduction to ML Safety 
            </a>
            &nbsp;course, and in BlueDot Impact's&nbsp;

            <a 
            className='link-text'
            style={{fontStyle: 'italic'}}
            href='https://aisafetyfundamentals.com/alignment/' target='_blank' rel='noopener noreferrer'>
            AI Safety Fundamentals: Alignment 
            </a>
            &nbsp;course.


            </div>
            <div style={{height: '20px'}}/>

            <h3 className='about-header'>Technical Skills</h3>
            {renderSkills()}

            <div style={{height: '200px'}}/>
        </div>
    )


};


export default About;
